<template>
  <div class="mobile-pricing-page">
    <div class="pricing container-fluid">
      <div class="container">
        <div class="pricing-box">
          <div class="title">
            <span>Pricing</span>
          </div>
          <div class="paragraph_1">
            <p class="yellow">
              Become a member
              <span class="white"
                >& join over 300.000 other Hoppers today!</span
              >
            </p>
          </div>
          <div class="paragraph_2">
            <p>
              Smart Forex is
              <span class="yellow">free to use</span>
              create your free account now!
            </p>
          </div>
          <div class="btn-box">
            <button>Try Smart Forex Free</button>
          </div>
          <div class="scroll-down-box">
            <span>Scroll down</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col tabs-nav">
          <div class="tabs-container">
            <div
              class="tabs"
              @click="setSwitcherFalse"
              :class="{ active: !switcher }"
            >
              Monthly
            </div>
            <div
              class="tabs"
              @click="setSwitcherTrue"
              :class="{ active: switcher }"
            >
              Yearly
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="!switherShow">
      <div class="pricing-table container-fluid">
        <div class="container">
          <flickity ref="flickity" :options="flickityOptions" class="carousel">
            <div class="carousel-cell">
              <div class="item-header">
                <div class="title">
                  <span>Pioneer</span>
                </div>
                <div class="low-title">
                  <span>Free package</span>
                </div>
                <div class="description">
                  <span>Free</span>
                </div>
              </div>
              <div class="item-body">
                <ul>
                  <li>20 positions</li>
                  <li>Portfolio management</li>
                  <li>Manual trading</li>
                  <li>On all available exchanges</li>
                </ul>
              </div>
              <div class="item-footer">
                <div class="btn-box">
                  <button>Get started</button>
                </div>
                <div class="learn-box">
                  <span>Learn more</span>
                </div>
              </div>
            </div>
            <div class="carousel-cell">
              <div class="item-header">
                <div class="title">
                  <span>Explorer</span>
                </div>
                <div class="low-title">
                  <span>Starter package</span>
                </div>
                <div class="description">
                  <span>$19.00</span>
                  <span class="month">/Month</span>
                  <span class="stock">Try 7 days for free!</span>
                </div>
              </div>
              <div class="item-body">
                <ul>
                  <li>80 positions</li>
                  <li>Max 15 selected coins</li>
                  <li>Max 2 triggers</li>
                  <li>TA with 10 min interval</li>
                  <li>1 Simulated Trading Bot</li>
                </ul>
              </div>
              <div class="item-footer">
                <div class="btn-box">
                  <button>Get started</button>
                </div>
                <div class="learn-box">
                  <span>Learn more</span>
                </div>
              </div>
            </div>
            <div class="carousel-cell">
              <div class="item-header">
                <div class="title">
                  <span>Adventurer</span>
                </div>
                <div class="low-title">
                  <span>Trader plus package</span>
                </div>
                <div class="description">
                  <span>$49.00</span>
                  <span class="month">/Month</span>
                </div>
              </div>
              <div class="item-body">
                <ul>
                  <li>80 positions</li>
                  <li>Max 15 selected coins</li>
                  <li>Max 2 triggers</li>
                  <li>TA with 10 min interval</li>
                  <li>1 Simulated Trading Bot</li>
                </ul>
              </div>
              <div class="item-footer">
                <div class="btn-box">
                  <button>Get started</button>
                </div>
                <div class="learn-box">
                  <span>Learn more</span>
                </div>
              </div>
            </div>
            <div class="carousel-cell best-values">
              <div class="best-values-block">
                <span>Best Values</span>
              </div>
              <div class="item-header">
                <div class="title">
                  <span>Hero</span>
                </div>
                <div class="low-title">
                  <span>Pro trader package</span>
                </div>
                <div class="description">
                  <span>$99.00</span>
                  <span class="month">/Month</span>
                </div>
              </div>
              <div class="item-body">
                <ul>
                  <li>500 positions</li>
                  <li>Max 75 selected coins</li>
                  <li>Max 10 triggers</li>
                  <li>TA with 2 min interval</li>
                  <li>All coins for signals</li>
                  <li>Market Making</li>
                  <li>Algorithm Intelligence</li>
                </ul>
              </div>
              <div class="item-footer">
                <div class="btn-box">
                  <button>Get started</button>
                </div>
                <div class="learn-box">
                  <span>Learn more</span>
                </div>
              </div>
            </div>
            <ol class="flickity-page-dots">
              <li
                class="dot is-selected"
                aria-label="Page dot 1"
                aria-current="step"
              ></li>
              <li class="dot" aria-label="Page dot 2"></li>
              <li class="dot" aria-label="Page dot 3"></li>
              <li class="dot" aria-label="Page dot 4"></li>
            </ol>
          </flickity>
          <div class="show-button-box">
            <button @click="isShow = !isShow">Show all features</button>
          </div>

          <div class="feature-list-item" :class="{ active: !isShow }">
            <div class="available-box">
              <div class="title">
                <span>Available on all exchanges</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <span>All exchanges</span>
                </div>
                <div class="item">
                  <span>All exchanges</span>
                </div>
                <div class="item">
                  <span>All exchanges</span>
                </div>
                <div class="item">
                  <span>All exchanges</span>
                </div>
              </div>
            </div>
            <div class="live-trading-box">
              <div class="title">
                <span>Live trading terminal</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
              </div>
            </div>
            <div class="portfolio-box">
              <div class="title">
                <span>Portfolio Management</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
              </div>
            </div>
            <div class="manual-box">
              <div class="title">
                <span>Manual Trading</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
              </div>
            </div>
            <div class="position-box">
              <div class="title">
                <span>Syncing Positions</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
              </div>
            </div>
            <div class="position-box">
              <div class="title">
                <span>Syncing Positions</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
              </div>
            </div>
            <div class="position-box">
              <div class="title">
                <span>Syncing Positions</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
              </div>
            </div>
            <div class="position-box">
              <div class="title">
                <span>Syncing Positions</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
              </div>
            </div>
            <div class="position-box">
              <div class="title">
                <span>Syncing Positions</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
              </div>
            </div>
            <div class="position-box">
              <div class="title">
                <span>Syncing Positions</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
              </div>
            </div>
            <div class="position-box">
              <div class="title">
                <span>Open Positions</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <span>20</span>
                </div>
                <div class="item">
                  <span>20</span>
                </div>
                <div class="item">
                  <span>20</span>
                </div>
                <div class="item">
                  <span>20</span>
                </div>
              </div>
            </div>
            <div class="automatic-box">
              <div class="title">
                <span>Automatic Trading</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
              </div>
            </div>
            <div class="automatic-box">
              <div class="title">
                <span>Automatic Trading</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
              </div>
            </div>
            <div class="automatic-box">
              <div class="title">
                <span>Automatic Trading</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="pricing-table container-fluid">
        <div class="container">
          <flickity ref="flickity" :options="flickityOptions" class="carousel">
            <div class="carousel-cell">
              <div class="item-header">
                <div class="title">
                  <span>Pioneer</span>
                </div>
                <div class="low-title">
                  <span>Free package</span>
                </div>
                <div class="description">
                  <span>Free</span>
                </div>
              </div>
              <div class="item-body">
                <ul>
                  <li>20 positions</li>
                  <li>Portfolio management</li>
                  <li>Manual trading</li>
                  <li>On all available exchanges</li>
                </ul>
              </div>
              <div class="item-footer">
                <div class="btn-box">
                  <button>Get started</button>
                </div>
                <div class="learn-box">
                  <span>Learn more</span>
                </div>
              </div>
            </div>
            <div class="carousel-cell">
              <div class="item-header">
                <div class="title">
                  <span>Explorer</span>
                </div>
                <div class="low-title">
                  <span>Starter package</span>
                </div>
                <div class="description">
                  <span>$19.00</span>
                  <span class="month">/Month</span>
                  <span class="stock">Try 7 days for free!</span>
                </div>
              </div>
              <div class="item-body">
                <ul>
                  <li>80 positions</li>
                  <li>Max 15 selected coins</li>
                  <li>Max 2 triggers</li>
                  <li>TA with 10 min interval</li>
                  <li>1 Simulated Trading Bot</li>
                </ul>
              </div>
              <div class="item-footer">
                <div class="btn-box">
                  <button>Get started</button>
                </div>
                <div class="learn-box">
                  <span>Learn more</span>
                </div>
              </div>
            </div>
            <div class="carousel-cell">
              <div class="item-header">
                <div class="title">
                  <span>Adventurer</span>
                </div>
                <div class="low-title">
                  <span>Trader plus package</span>
                </div>
                <div class="description">
                  <span>$49.00</span>
                  <span class="month">/Month</span>
                </div>
              </div>
              <div class="item-body">
                <ul>
                  <li>80 positions</li>
                  <li>Max 15 selected coins</li>
                  <li>Max 2 triggers</li>
                  <li>TA with 10 min interval</li>
                  <li>1 Simulated Trading Bot</li>
                </ul>
              </div>
              <div class="item-footer">
                <div class="btn-box">
                  <button>Get started</button>
                </div>
                <div class="learn-box">
                  <span>Learn more</span>
                </div>
              </div>
            </div>
            <div class="carousel-cell best-values">
              <div class="best-values-block">
                <span>Best Values</span>
              </div>
              <div class="item-header">
                <div class="title">
                  <span>Hero</span>
                </div>
                <div class="low-title">
                  <span>Pro trader package</span>
                </div>
                <div class="description">
                  <span>$99.00</span>
                  <span class="month">/Month</span>
                </div>
              </div>
              <div class="item-body">
                <ul>
                  <li>500 positions</li>
                  <li>Max 75 selected coins</li>
                  <li>Max 10 triggers</li>
                  <li>TA with 2 min interval</li>
                  <li>All coins for signals</li>
                  <li>Market Making</li>
                  <li>Algorithm Intelligence</li>
                </ul>
              </div>
              <div class="item-footer">
                <div class="btn-box">
                  <button>Get started</button>
                </div>
                <div class="learn-box">
                  <span>Learn more</span>
                </div>
              </div>
            </div>
            <ol class="flickity-page-dots">
              <li
                class="dot is-selected"
                aria-label="Page dot 1"
                aria-current="step"
              ></li>
              <li class="dot" aria-label="Page dot 2"></li>
              <li class="dot" aria-label="Page dot 3"></li>
              <li class="dot" aria-label="Page dot 4"></li>
            </ol>
          </flickity>
          <div class="show-button-box">
            <button @click="isShow = !isShow">Show all features</button>
          </div>

          <div class="feature-list-item" :class="{ active: !isShow }">
            <div class="available-box">
              <div class="title">
                <span>Available on all exchanges</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <span>All exchanges</span>
                </div>
                <div class="item">
                  <span>All exchanges</span>
                </div>
                <div class="item">
                  <span>All exchanges</span>
                </div>
                <div class="item">
                  <span>All exchanges</span>
                </div>
              </div>
            </div>
            <div class="live-trading-box">
              <div class="title">
                <span>Live trading terminal</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
              </div>
            </div>
            <div class="portfolio-box">
              <div class="title">
                <span>Portfolio Management</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
              </div>
            </div>
            <div class="manual-box">
              <div class="title">
                <span>Manual Trading</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
              </div>
            </div>
            <div class="position-box">
              <div class="title">
                <span>Syncing Positions</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
              </div>
            </div>
            <div class="position-box">
              <div class="title">
                <span>Syncing Positions</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
              </div>
            </div>
            <div class="position-box">
              <div class="title">
                <span>Syncing Positions</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
              </div>
            </div>
            <div class="position-box">
              <div class="title">
                <span>Syncing Positions</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
              </div>
            </div>
            <div class="position-box">
              <div class="title">
                <span>Syncing Positions</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
              </div>
            </div>
            <div class="position-box">
              <div class="title">
                <span>Syncing Positions</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
                <div class="item">
                  <img src="./img/success.png" alt="success" />
                </div>
              </div>
            </div>
            <div class="position-box">
              <div class="title">
                <span>Open Positions</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <span>20</span>
                </div>
                <div class="item">
                  <span>20</span>
                </div>
                <div class="item">
                  <span>20</span>
                </div>
                <div class="item">
                  <span>20</span>
                </div>
              </div>
            </div>
            <div class="automatic-box">
              <div class="title">
                <span>Automatic Trading</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
              </div>
            </div>
            <div class="automatic-box">
              <div class="title">
                <span>Automatic Trading</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
              </div>
            </div>
            <div class="automatic-box">
              <div class="title">
                <span>Automatic Trading</span>
              </div>
              <div class="exchanges-list">
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
                <div class="item">
                  <img src="./img/danger.png" alt="danger" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="mobile-smart-trading container-fluid">
      <div class="container">
        <div class="smart-trading-box">
          <div class="low-title">
            <span>Free to use – no credit card required</span>
          </div>
          <div class="title">
            <span>Start trading with Smart Forex for Free!</span>
          </div>
          <div class="description">
            <span
              >Choosing a trading account is always difficult, you need to get
              acquainted with all account types and choose the best one of them.
              In Smart FX, we like to simplify everything for our beloved
              clients. An optimal trading account for all your needs.</span
            >
          </div>
          <div class="btn-box">
            <button>Start now</button>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-cards container-fluid">
      <div class="container">
        <div class="title">
          <span>We Accept</span>
        </div>
        <div class="first-list-item">
          <div class="item">
            <img src="./img/Vector.png" alt="skrill" />
          </div>
          <div class="item">
            <img src="./img/visa.png" alt="visa" />
          </div>
          <div class="item">
            <img src="./img/mc.png" alt="master card" />
          </div>
        </div>
        <div class="second-list-item">
          <div class="item">
            <img src="./img/neteller.png" alt="neteller" />
          </div>
          <div class="item">
            <img src="./img/wire.png" alt="wire" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Flickity from 'vue-flickity'
export default {
  components: {
    Flickity,
  },

  data() {
    return {
      flickityOptions: {
        initialIndex: 1,
        freeScroll: true,
        contain: true,
        prevNextButtons: false,
        pageDots: true,
        wrapAround: true,
        draggable: true,
      },
      isShow: false,
      switcher: false,
    }
  },

  methods: {
    next() {
      this.$refs.flickity.next()
    },
    setSwitcherTrue() {
      this.switcher = true
    },
    setSwitcherFalse() {
      this.switcher = false
    },
    previous() {
      this.$refs.flickity.previous()
    },
  },
  computed: {
    switherShow() {
      return this.switcher
    },
  },
}
</script>

<style lang="scss" src="./styles/mobileStyle.scss"></style>
